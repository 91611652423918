import linkedinIcon from '../pictures/linkedin-icon.png'
import mailIcon from '../pictures/mail-icon.png'
import githubIcon from '../pictures/github-icon.png'
import devtoIcon from '../pictures/devto-icon.png'
import ReactTooltip from 'react-tooltip';
import { useState } from 'react';
//buttons
import PageButtons from './PageButtons';
function ContactPage(props) {
    const [copiedConfirmation, setCopiedConfirmation] = useState('');

    const showConfirmationForThreeSeconds = () =>{
        setCopiedConfirmation('Copied');
        setTimeout(() => {
            setCopiedConfirmation('')
            console.log('hello')
        }, 3000);
    }

    const handleMailIconClick = () => {
        navigator.clipboard.writeText('geraldarzola@gmail.com');
        showConfirmationForThreeSeconds();
    }
    
    const handleLinkedInClick = () => {
        window.open('https://www.linkedin.com/in/gerald-arzola-abb7961a8/', "_blank")
    }
    return(
        <>
            <div className="contactContainer">
                <h1>Let's connect!</h1>
                <br/>
                <div>
                    
                    <div className='row' data-type= 'light' data-effect='solid' data-place='bottom' data-tip='Open in new tab'>
                        <img id="linkedInIcon" onClick={handleLinkedInClick} className="contactIcon" src={linkedinIcon} alt="linked in link"/>
                        <label id="linkedInIconLabel" className="contactIconLabel" onClick={handleLinkedInClick} for="linkedInIcon">Gerald Arzola</label>
                    </div>

                    <div className='row' data-type= 'light' data-effect='solid' data-place='bottom' data-tip='Open in new tab'>
                        <img id="githubIcon" onClick={()=>window.open('https://www.github.com/geraldarzy', "_blank")} className="contactIcon" src={githubIcon} alt="github icon"/>
                        <label id="githubIconLabel" className="contactIconLabel" onClick={()=>window.open('https://www.github.com/geraldarzy', "_blank")} for="githubIcon">@geraldarzy</label>
                    </div>

                    <div className='row' data-type= 'light' data-effect='solid' data-place='bottom' data-tip='Open in new tab'>
                        <img id="devtoIcon" onClick={()=>window.open('https://www.dev.to/geraldarzy', "_blank")} className="contactIcon" src={devtoIcon} alt="devto articles"/>
                        <label id="devtoIconLabel" className="contactIconLabel" onClick={()=>window.open('https://www.dev.to/geraldarzy', "_blank")} for="devtoIcon">@geraldarzy</label>
                    </div>

                    <div className='row' data-type= 'light' data-effect='solid' data-place='bottom' data-tip='Copy to clipboard'>
                        <img id="mailIcon" className="contactIcon"  src={mailIcon} onClick={() => handleMailIconClick() } alt="contact"/>
                        <label id="mailIconLabel" className="contactIconLabel" for="mailIcon" onClick={() => handleMailIconClick()}>geraldarzola@gmail.com</label>
                        <p style={{fontSize:'.75rem'}}>{copiedConfirmation}</p>
                    </div>
                </div>
                <ReactTooltip />
            </div>
                <div className="contact-buttons">
                    <PageButtons boldPage="contactPage"/>
                </div>
        </>
    )
}

export default ContactPage;