// gifs
import thriftyGif from '../pictures/thrifty.gif'
import ecoRoutesGif from '../pictures/eco-routes.gif'
import tracksGif from '../pictures/tracks-gif.gif'
import myendeavorsGif from '../pictures/my-endeavors-gif.gif'
//gif-stills
import thriftyGifSC from '../pictures/gif-screenshots/thrifty-sc.png'
import ecoRoutesGifSC from '../pictures/gif-screenshots/eco-routes-sc.png'
import tracksGifSC from '../pictures/gif-screenshots/tracks-gif-sc.png'
import myendeavorsGifSC from '../pictures/gif-screenshots/my-endeavors-gif-sc.png'

// icons
import reactIcon from '../pictures/react-icon.png'
import rubyIcon from '../pictures/ruby-icon.png'
import jsIcon from '../pictures/js-icon.png'
import postgresqlIcon from '../pictures/postgresql-icon.png'
import sqlIcon from '../pictures/sql-icon.png'
import rubyOnRailsIcon from '../pictures/ruby-on-rails-icon.png'
import sinatraIcon from '../pictures/sinatra-icon.png'
// components
import ProjectCard from './ProjectCard'
import PageButtons from './PageButtons';
import { useState } from 'react';

function ProjectsPage(){

    const [ecoRoutesDynamicGif, setEcoRoutesDynamicGif] = useState(ecoRoutesGifSC);
    const [thriftyDynamicGif, setThriftyDynamicGif] = useState(thriftyGifSC);
    const [tracksDynamicGif, setTracksDynamicGif] = useState(tracksGifSC);
    const [myendeavorsDynamicGif, setMyendeavorsDynamicGif] = useState(myendeavorsGifSC);

    const ecoRoutesDescription = "Uses MapBoxAPI to find and display the most eco-friendly route for your trip. Calculates your trips carbon-footprint and allows you to compare and save your overall carbon-footprint.";
    const thriftyDescription = "Mock e-commerce web-app designed to be a hub for local NYC thriftshops. Browse, add to cart, order. Why shop at one thriftshop, when you can shop all thriftshops at once?";
    const tracksDescription = "Social media skeleton that uses each NYC Subway station as a page. A user can check in to a station of their choice and make a post that is visible to the other users that are tuned into that station";
    const endeavorsDescription = "Self blog bucketlist. A user can make a list of 'Endeavors' they'd like to go on. As you complete your endeavors, you can check them off and include pictures and a short description.";
    return (
    <>
        <div className="projects-row">
            <ProjectCard setState={setEcoRoutesDynamicGif} movingGif={ecoRoutesGif} scGif={ecoRoutesGifSC} gif={ecoRoutesDynamicGif} title="Eco-Routes" builtWith={{'React':reactIcon,'Ruby on Rails':rubyOnRailsIcon, 'PostgreSQL':postgresqlIcon}} projectDescription={ecoRoutesDescription} buttons={{'Live Demo':'https://gracious-keller-d2538b.netlify.app', 'Demo':'https://www.loom.com/share/c35fa712417e4145a5c0d8c3bafa2508', 'Frontend':'https://github.com/geraldarzy/eco-routes-frontend'}}/>
            <ProjectCard setState={setThriftyDynamicGif} movingGif={thriftyGif} scGif={thriftyGifSC} gif={thriftyDynamicGif} title="Thrifty" builtWith={{'JavaScript':jsIcon, 'Ruby on Rails':rubyOnRailsIcon, 'PostgreSQL':postgresqlIcon}} projectDescription={thriftyDescription} buttons={{'Frontend':'https://github.com/geraldarzy/thrifty-frontend', 'Backend':'https://github.com/geraldarzy/thrifty-backend', 'Demo':'https://www.loom.com/share/3d493dea7bcd421f9d1f845d52e97eae'}}/>
            <ProjectCard setState={setTracksDynamicGif} movingGif={tracksGif} scGif={tracksGifSC} gif={tracksDynamicGif} title="Tracks" builtWith={{'Ruby':rubyIcon, 'Ruby on Rails':rubyOnRailsIcon, 'PostgreSQL':postgresqlIcon}} projectDescription={tracksDescription} buttons={{'Repository':'https://github.com/geraldarzy/tracks', 'Demo':'https://www.loom.com/share/8b99a397592f4ce4be1128e031f4a9b9'}}/>
            <ProjectCard setState={setMyendeavorsDynamicGif} movingGif={myendeavorsGif} scGif={myendeavorsGifSC} gif={myendeavorsDynamicGif} title="Endeavors" builtWith={{'Ruby':rubyIcon, 'Sinatra':sinatraIcon, 'SQLite 3':sqlIcon}} projectDescription={endeavorsDescription} buttons={{'Repository':'https://github.com/geraldarzy/my-endeavors', 'Demo':'https://www.loom.com/share/54701d03a847420c9cf3e50fb62bac37'}}/>
        </div>
        <div className="project-buttons">
            <PageButtons boldPage="projectsPage"/>
        </div>
    </>
    )
}

export default ProjectsPage;