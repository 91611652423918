import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// components
import AboutMePage from './components/AboutMePage';
import HomePage from './components/HomePage';
import ProjectsPage from './components/ProjectsPage';
import ContactPage from './components/ContactPage';

import { useState, useEffect } from 'react';

import HorizontalScroll from '@oberon-amsterdam/horizontal';
import { isMobileOnly } from 'react-device-detect';

const horizontal = new HorizontalScroll()
function App() {
  if(isMobileOnly){
    horizontal.destroy();
    console.log('on mobile!');
  }
  const [offsetX, setOffsetX] = useState(0);

    const handleScroll = () => {setOffsetX(window.pageXOffset)}

    useEffect(()=>{
      window.addEventListener("scroll", handleScroll);

      return ()=> window.removeEventListener("scroll", handleScroll);
    })
    
  return (
    <>
        <section style={{backgroundPositionX: offsetX}} id="home-page" className="scrollSide-container">
          <HomePage/>
        </section>
        
        <section id="about-page" style={{backgroundPositionX: (offsetX*.01)}} className="scrollSide-container">
          <AboutMePage/>
        </section>

        <section id="projects-page" style={{backgroundPositionX: (offsetX*.01)}} className="scrollSide-container">
          <ProjectsPage/>
        </section>

        <section id="contact-page" style={{backgroundPositionX: offsetX}} className="scrollSide-container">
          <ContactPage/>
        </section>

    </>
  );
}

export default App;
