import BigName from './BigName';
import { useState, useEffect } from 'react';

import linkedinIcon from '../pictures/linkedin-icon.png'
import githubIcon from '../pictures/github-icon.png'
import devtoIcon from '../pictures/devto-icon.png'

import Typewriter from 'typewriter-effect';
import PageButtons from './PageButtons';
function HomePage(){
    const [title, setTitle] = useState(['Gerald Arzola', 'bigName']);
    const [offsetX, setOffsetX] = useState(0);

    const handleScroll = () => {setOffsetX(window.pageXOffset)}

    useEffect(()=>{
        window.addEventListener("scroll", handleScroll);

        return ()=> window.removeEventListener("scroll", handleScroll);
    })
    
    return(
        <div>
            <BigName style={{ transform: `translateX(${offsetX*.79}px)`,cursor:'default'}} title={title} setTitle={setTitle}/>
            <Typewriter
                options={{
                    strings: ['Fullstack Developer', 'Software Engineer', 'NYC Based', 'Participating in weekend Hackathons until I get hired'],
                    autoStart: true,
                    loop: true,

                    deleteSpeed: 1,
                }}
            />
            <div style={{ transform: `translateX(${offsetX*.79}px)`}} className="home-buttons">

                <PageButtons boldPage="homePage"/>
            </div>
            <div>
            <img style={{ transform: `translate(${offsetX*.9}px, ${offsetX*.4}px)`}} className="homeIcon" onClick={()=>window.open('https://www.linkedin.com/in/gerald-arzola-abb7961a8/', "_blank")} src={linkedinIcon} alt='linked in social media icon' />
            <img style={{ transform: `translate(${offsetX*.9}px, ${offsetX*.3}px)`}} className="homeIcon" onClick={()=>window.open('https://github.com/geraldarzy', "_blank")} src={githubIcon} alt='github icon'/>
            <img style={{ transform: `translate(${offsetX*.9}px, ${offsetX*.2}px)`}} className="homeIcon" onClick={()=>window.open('https://dev.to/geraldarzy', "_blank")} src={devtoIcon} alt='devto icon'/>
            </div>

        </div>
    )
}

export default HomePage;