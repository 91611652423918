import {Card, Button} from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { useState, useEffect } from 'react';
function ProjectCard(props){

    const [offsetX, setOffsetX] = useState(0);

    const handleScroll = () => {setOffsetX(window.pageXOffset)}

    useEffect(()=>{
        window.addEventListener("scroll", handleScroll);

        return ()=> window.removeEventListener("scroll", handleScroll);
    })

    return(
        <>
            <Card onMouseEnter={()=>props.setState(props.movingGif)} onMouseLeave={()=>props.setState(props.scGif)} style={{boxShadow:`${offsetX/1000}px 5px 10px darkgray`,transform: `translateX(${offsetX*.001}px)`}}>
            <Card.Img onClick={()=>{window.open(props.buttons['Demo'], "_blank")}} variant="top" src={props.gif} />
            <Card.Body>
                <Card.Title>{props.title}</Card.Title>
                <Card.Text className="builtWith row">
                    {
                        Object.keys(props.builtWith).map(key=>{
                            return(
                                <img data-type='light' data-effect='solid' data-place='bottom' data-tip={key} src={props.builtWith[key]} className="builtWith-icon" alt={`built with ${key}`} />
                            )
                        })
                    }
                </Card.Text>
                <Card.Text className='card-description'>
                    {props.projectDescription}
                </Card.Text>
                <Card.Text className="project-card-buttons">
                    {
                        Object.keys(props.buttons).map(key=>{
                            return(
                                <Button className="projectCard-button"
                                    onClick={()=>{
                                        window.open(props.buttons[key], "_blank")
                                    }}
                                    variant='light'>
                                        {key}
                                </Button>
                            )
                        })
                    }
                </Card.Text>

            </Card.Body>
            <ReactTooltip />
            </Card>
        </>
    )
}

export default ProjectCard;