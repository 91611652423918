import React from 'react';

function BigName(props){

    const handleMouseClick=()=>{
        props.setTitle(['My friends call me Arzy, but you can call me your next employee of the month','smallName'])
        setTimeout(() => {
            props.setTitle(['Gerald Arzola','bigName'])
        }, 2500);
    }
    return(
        <h1 style={props.style} className={props.title[1]} onClick={handleMouseClick}>{props.title[0]}</h1>
    )

}

export default BigName;