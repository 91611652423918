import { HashLink } from 'react-router-hash-link';
import resume from '../geraldarzola-resume.pdf'
const PageButtons = (props) => {
    return (
        <>
            <HashLink className={"hashLink" +  (props.boldPage === 'homePage' ? " boldPage" : "" )} smooth to='#home-page'>
                Home
            </HashLink>

            <HashLink className={"hashLink" +  (props.boldPage === 'aboutPage' ? " boldPage" : "" )} smooth to='#about-page'>
                About Me
            </HashLink>

            <HashLink className={"hashLink" +  (props.boldPage === 'projectsPage' ? " boldPage" : "" )} smooth to='#projects-page'>
                Projects
            </HashLink>

            <a className="hashLink" href={resume} target="_blank" rel="noreferrer">
                Resume
            </a>

            <HashLink className={"hashLink" +  (props.boldPage === 'contactPage' ? " boldPage" : "" )} smooth to='#contact-page'>
                Contact
            </ HashLink>
        </>
    )
}

export default PageButtons
