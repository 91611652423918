import me from "../pictures/geraldarzola.png";
import waiterIcon from "../pictures/waiter-icon.png";
import worldIcon from "../pictures/world-icon.png";
import capIcon from "../pictures/cap-icon.png";
import devIcon from "../pictures/dev-icon.png";

import { useState, useEffect } from "react";
import PageButtons from "./PageButtons";

function AboutMePage(params) {
    const [offsetX, setOffsetX] = useState(0);

    const handleScroll = () => {
        setOffsetX(window.pageXOffset);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    });

    return (
        <div style={{ transform: `translateX(${offsetX * 0.01}px)` }}>
            <div className="aboutMeTextContainer row">
                <img
                    className="aboutMeSelfie aboutMeIcon"
                    src={me}
                    alt="me in nyc"
                />
                <p className="col descriptionText">
                    The struggle of being named after your father is the
                    confusion that comes along with it. Ever since I've been on
                    this earth, I've always just gone by{" "}
                    <strong>
                        <i>Gerald.</i>
                    </strong>
                </p>
            </div>

            <div className="aboutMeTextContainer row">
                <p className="col descriptionText">
                    Since highschool and all through out college, I've been
                    working in the restaurant industry. With my university being
                    in Queens and work being in Manhattan, I was able to balance
                    them until I went away for SUNY at Buffalo.
                </p>
                <img src={waiterIcon} className="aboutMeIcon" alt="waiter"/>
            </div>

            <div className="aboutMeTextContainer row">
                <img
                    src={capIcon}
                    className="aboutMeIcon"
                    alt="graduation cap"
                />
                <p className="col descriptionText">
                    Struggling to afford tuition to finish my Computer Science
                    degree, I took a break from school. I started working as a
                    server for a hotel in Hamptons, NY. I didn't want to get
                    stuck so I chased my passion and went all in with a coding
                    bootcamp.{" "}
                </p>
            </div>

            <div className="aboutMeTextContainer row">
                <p className="col descriptionText">
                    During my time in Flatiron's Sofware Engineering Program, I
                    followed a tight schedule. Along the way, I've completed
                    various projects that not only showcase my skillset, but
                    also my passion for creating things to solve problems in the
                    real world.
                </p>
                <img src={devIcon} className="aboutMeIcon" alt="dev code" />
            </div>

            <div className="aboutMeTextContainer row">
                <img
                    src={worldIcon}
                    className="aboutMeIcon"
                    alt="a globe"
                />
                <p className="col descriptionText">
                    Born in the Philippines and raised in Queens, New York.
                    Molded my experience of being taught the Philippines culture
                    while being surrounded and influenced by NYC's melting pot
                    of a culture.
                </p>
            </div>

            <div className="about-buttons">
                <PageButtons boldPage="aboutPage" />
            </div>
        </div>
    );
}

export default AboutMePage;
